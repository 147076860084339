<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" v-if="!cashout">
        <v-card-text class="text-center">
          <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="loading && !error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Verificando sangria, por favor aguarde
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
      <div v-if="cashout">
        <v-card class="rounded-lg">
          <v-card-text>
            <h4 class="text-center mb-0">
              Sangria #{{ cashout.id | shortId }}
            </h4>
            <h6 class="text-center">Sangria verificada com sucesso</h6>

            <div class="d-flex flex-column align-center mt-4">
              <h6 class="mb-0">Valor sangrado</h6>
              <h4 class="mb-0">{{ cashout.amount | currency }}</h4>
              <p>em {{ cashout.createdAt | date("DD/MM/YYYY HH:mm") }}</p>
            </div>

            <v-card class="pa-3" outlined>
              <b>{{ cashout?.Session?.partyId ? "Bilheteria" : "Ponto de venda" }}</b>
              <h6 class="mb-0">
                {{ cashout?.Session?.name || cashout?.Session?.Address?.name }}
              </h6>
              <span>{{ cashout?.Session?.Device?.serialNumber }}</span>
            </v-card>

            <v-card class="pa-3 mt-2" outlined>
              <b>Realizada por</b>
              <div class="d-flex gap-2 align-center">
                <base-avatar
                  :src="cashout.User.photo"
                  :seed="cashout.User.id"
                  :size="24"
                />
                <h6 class="mb-0">{{ cashout.User.name }}</h6>
              </div>
            </v-card>
          </v-card-text>
        </v-card>
        <v-btn block href="/" text class="mt-2" color="white">
          ir para o app
        </v-btn>
      </div>
    </div>
    <re-captcha
      ref="recaptcha"
      auto-run
      @error="recaptchaError"
      @close="recaptchaError"
      @success="readToken"
    ></re-captcha>
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha";
import VERIFY from "@/services/verify";
import { mapGetters } from "vuex";

export default {
  name: "VerifyCashout",
  metaInfo: {
    title: "Verificar Sangria",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      innerLoading: false,
      innerError: false,
      cashout: null,
    };
  },
  methods: {
    recaptchaError() {
      this.loading = false;
      this.innerLoading = false;
      this.error =
        "Ocorreu ao validar o recaptcha, atualize a página e tente novamente.";
    },

    async readToken(recaptcha) {
      try {
        let id = this.$route.params.id;
        let { cashout } = await VERIFY.cashout(id, recaptcha);
        this.loading = false;
        this.innerLoading = false;
        this.cashout = cashout;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (!id) {
      this.loading = false;
      this.error =
        "O código de sangria não foi informado, por favor, informe o código e tente novamente.";
    }
  },
  components: {
    ReCaptcha,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
